import slave from '@nsftx/seven-gravity-gateway/slave';
import Retail from '@nsftx/seven-gravity-gateway/plugin-retail';

/**
   *
  * @class IframePubSub
  * @memberOf module:"7Shop.IframePubSub"
  */
/* @ngInject */
function IframePubSub($rootScope, $document, switchView, focusNext, SevenTicketPreviewService) {
  const self = this;
  $rootScope.$on('7S:Widget.UpdateProductSettings', (e, eventData) => {
    eventData.data.productId = eventData.productId;
    this.publish('Widget.UpdateProductSettings', eventData.data, {
      enforceEvent: true
    });
  });

  $rootScope.$on('7S:Peripherals.Print', (e, eventData) => {
    this.publish('Peripherals.Print', eventData.data, {
      enforceEvent: true
    });
  });

  $rootScope.$on('7S:Peripherals.UpdateConfig', (e, eventData) => {
    eventData.data.productId = eventData.productId;
    this.publish('Peripherals.UpdateConfig', eventData.data, {
      enforceEvent: true
    });
  });

  $rootScope.$on('7S:Tickets.Print', (e, eventData) => {
    this.publish('Tickets.Print', eventData, {
      enforceEvent: true
    });
  });

  $rootScope.$on('7S:Dialog.ShowModal', (e, eventData) => {
    this.publish('Dialog.ShowModal', eventData.data || eventData);
  });

  $rootScope.$on('7S:Dialog.CloseModal', (e, eventData) => {
    this.publish('Dialog.CloseModal', eventData.data || eventData);
  });

  $rootScope.$on('7S:Peripherals.PrintA4', (e, eventData) => {
    this.publish('Peripherals.PrintA4', eventData);
  });

  $rootScope.$on('7S:Widget.UpdateSettings', (e, eventData) => {
    this.publish('Widget.UpdateSettings', eventData);
  });

  $rootScope.$on('7S:UI.Show', (e, eventData) => {
    this.publish('UI.Show', eventData);
  });

  $rootScope.$on('7S:UI.Hide', (e, eventData) => {
    this.publish('UI.Hide', eventData);
  });

  $rootScope.$on('7S:Tickets.Checked', (e, eventData) => {
    const driver = self._slave;
    driver.emit({
      action: 'EventPublisher.Publish',
      data: SevenTicketPreviewService.mapTicketForPreview(eventData),
      enforceEvent: true
    });
  });

  /**
   * Event for updating ticket state
   *
   * @event "7S:Tickets.Update"
   * @type {Object}
   *
   * @property {String} action
   * @property {String} printAction
   * @property {String} ticketData.requestUuid
   * @property {String} ticketData.id
   * @property {String} ticketData.product
   * @property {Object} ticketData.status
   * @property {String} ticketData.status.value
   * @property {String} [showMessage] - show final message to user or skip
   */
  $rootScope.$on(
    '7S:Tickets.Update',
    /**
     *
     * @function
     *
     * @param event
     *
     * @param {Object} data
     * @param {String} data.action - Ticket action: Add, Cancel or Payout
     * @param {String} data.printAction - Print action from inside print service, for Add action it is empty string
     *                                  and for other same as action
     * @param {Object} data.ticketData.requestUuid - Uuid of Seven Platform ticket request
     * @param {Object} data.ticketData.id - Seven Platform ticket id
     * @param {Object} data.ticketData.product - Seven Platform id of product
     * @param {Object} data.ticketData.status - Status of ticket
     * @param {String} data.ticketData.status.value - Status of ticket: open, accepted, in_play, rejected
     *                                                closed, payedout, cancelled, used, won, lost, afterclosed
     * @param {Boolean} data.showMessage - Show message from ticket response
     * @param {Boolean} data.doPrint - To print ticket or not
     * @listens "7S:Tickets.Update"
     */
    function (event, data) {
      const driver = self._slave;
      driver.emit({
        action: 'Tickets.Update',
        data: {
          action: data.action,
          printAction: data.printAction,
          ticketData: data.ticketData,
          showMessage: data.showMessage,
          doPrint: data.doPrint
        },
        enforceEvent: true
      });
    }
  );

  /**
   * @type {Object}
   * @memberOf module:"7Shop.IframePubSub".IframePubSub
   */
  this._slave = null;
  /**
   * @memberOf module:"7Shop.IframePubSub".IframePubSub
   * @param {Object} config
   * @param {String} config.slaveId - Id of our frame (Shop is slave)
   * @param {Array} config.allowedOrigins - Array of allowed origins
   * @param {Boolean} [config.debug=false] - Ouput debug messages
   */
  this.addFrame = function (config) {
    let e;
    config.plugins = [new Retail()];
    this._slave = slave(config);

    // listen for all events from parent frame and
    // emits them using angularjs pub/sub
    this._slave.on('*', function (event) {
      if (event.productId) {
        // get product id
        event.data.productId = event.productId;
      }
      switch (event.action) {
      case 'Master.Event':
        // event keys coming from master
        e = $.Event(event.event);
        angular.extend(e, event);
        angular.element($document).trigger(e);
        break;
      case 'Widget.Event':
        $rootScope.$evalAsync(function () {
          if (event.data?.shortcut) {
            $rootScope.$broadcast(
              'KeyPressed',
              event.data.shortcut.toLowerCase()
            );
          }
          $rootScope.$emit('7S:' + event.action, event.data);
        });
        break;
      case 'User.AuthorizationChanged':
        sessionStorage.shopToken = event.data.user.auth.accessToken;
        break;
      case 'Tickets.PayingSentSuccess':
        focusNext(10);
        break;
      case 'Tickets.PayingFailed':
        focusNext(31);
        break;
      case 'UI.Show':
        switchView.selectService(event.productId);
        break;
      default:
        $rootScope.$evalAsync(function () {
          $rootScope.$emit('7S:' + event.action, event.data);
        });
      }
    });
  };

  /**
   * Publish message to parent frame
   *
   * @memberOf module:"7Shop.IframePubSub".IframePubSub
   * @param {String} action - Name of actions to publish to upper frame
   * @param {Object} data - Additional data to send
   * @param {Object} options - Gateway options
   */
  this.publish = function (action, data, options) {
    this._slave.sendMessage({
      action: action,
      enforceEvent: !!options?.enforceEvent,
      data: data
    });
  };

  this.getDriver = function () {
    return this._slave;
  };
}

export default IframePubSub;
