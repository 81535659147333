import { each, capitalize } from 'lodash';

(function () {
  angular.module('7Shop.Common')
    .factory('SevenTicketPreviewService', sevenTicketPreviewService);

  function sevenTicketPreviewService(
    nabTrans,
  ) {
    function formatFirstBallColorLuckyX(bet) {
      const numbers = [2, 4, 8, 16, 1];
      const outcomes = ['red', 'blue', 'green', 'yellow', 'purple'];

      const outcomeIndex = numbers.indexOf(bet.value);
      return capitalize(nabTrans.translate(`main.${outcomes[outcomeIndex]}_color`, {}, true));
    }

    function formatFirstBallColorLuckySix(bet) {
      const colors = [];
      let colorsString = '';
      let colorIndex = '';
      const colorIndexArray = [];
      const outcomes = ['red', 'green', 'blue', 'purple', 'brown', 'yellow', 'orange', 'black'];
      each([0, 1, 2, 3, 4, 5, 6, 7], (n) => {
        // eslint-disable-next-line no-bitwise
        if (parseInt(bet.value, 10) & (2 ** n)) {
          colorIndex = n === 0 ? 7 : n - 1;
          colorIndexArray.push(colorIndex);
        }
      });
      colorIndexArray.sort((a, b) => a - b);
      for (let i = 0; i < colorIndexArray.length; i += 1) {
        const colorTranslation = capitalize(nabTrans.translate(`main.${outcomes[colorIndexArray[i]]}_color`, {}, true));
        colors.push(colorTranslation);
        colorsString = colors.join(' ');
      }
      return colorsString;
    }

    function getOutcome(bet, product) {
      switch (product) {
      case 'LuckySix': {
        switch (bet.type) {
        case 5:
          return formatFirstBallColorLuckySix(bet);
        case 7:
        case 11: {
          const outcome = bet.value === '0' ? 'luckysix.outcome_even' : 'luckysix.outcome_odd';
          return nabTrans.translate(outcome, {}, true);
        }
        case 6:
        case 8: {
          const outcome = bet.value === '0' ? 'luckysix.outcome_low' : 'luckysix.outcome_high';
          return nabTrans.translate(outcome, {}, true);
        }
        default:
          return bet.value;
        }
      }
      case 'LuckyX': {
        switch (bet.type) {
        case 22: {
          return formatFirstBallColorLuckyX(bet);
        }
        case 21:
        case 25: {
          const outcome = bet.value === '0' ? 'luckysix.outcome_low' : 'luckysix.outcome_high';
          return nabTrans.translate(outcome, {}, true);
        }
        case 24: {
          const outcome = bet.value === '0' ? 'luckysix.outcome_even' : 'luckysix.outcome_odd';
          return nabTrans.translate(outcome, {}, true);
        }
        default: {
          if (Array.isArray(bet.value)) return bet.value.join(',');
          return bet.value;
        }
        }
      }
      default:
        // For races
        switch (bet.type) {
        case 0:
        case 1:
        case 2:
        case 5:
        case 6:
        case 7: {
          return `${nabTrans.translate('games_racer', {}, true)} - ${bet.value}`;
        }
        case 3:
        case 4:
        case 9: {
          const outcome = bet.value === '0' ? 'luckysix.outcome_low' : 'luckysix.outcome_high';
          return nabTrans.translate(outcome, {}, true);
        }
        case 8: {
          const outcome = bet.value === '0' ? 'luckysix.outcome_even' : 'luckysix.outcome_odd';
          return nabTrans.translate(outcome, {}, true);
        }
        case 10:
        case 11: {
          return bet.value.map((arr) => arr.join(',')).join(' - ');
        }
        case 12:
        case 13: {
          return bet.value.join(',');
        }
        case 14: {
          const outcomes = bet.value.flat().map(Number);
          const racerTranslation = nabTrans.translate('games_racer', {}, true);
          return `${racerTranslation} ${outcomes[0]} - ${racerTranslation} ${outcomes[1]}`;
        }
        default:
          return bet.value;
        }
      }
    }

    return {
      mapTicketForPreview({ ticket }) {
        const {
          bets, product, translation, payin, payinTax, maxPossibleWin, minPossibleWin
        } = ticket;

        const mappedBets = bets.map((bet, index) => {
          let status = bet.status.value;

          // Map status to match seven ticket preview status color
          if (status === 'PAYEDOUT') status = 'WON';
          if (status === 'CLOSED') status = 'LOST';

          let betOdd = bet.odd && bet.odd !== 0 ? bet.odd.toFixed(2) : null;
          if (bet.combinations && bet.combinations.length === 1) {
            betOdd = bet.combinations[0].odd.toFixed(2);
          }

          return {
            betId: index + 1,
            betStartTime: `${bet.eventId}`,
            betEventName: bet.typeValue ?? bet.bet.title,
            betName: bet.category,
            betOutcomeName: getOutcome(bet, product),
            betOutcomeOdd: betOdd ?? null,
            result: bet.status.value,
            payment: bet.payin,
            resolutionStatus: status
          };
        });

        return {
          title: translation,
          productId: product,
          channel: '7ShopTickets',
          action: 'Checked',
          fullEventName: 'Tickets.Checked',
          ticket: {
            bets: mappedBets,
            payment: payin,
            paymentTax: payinTax,
            stake: payin - payinTax,
            maxWinning: maxPossibleWin,
            minWinning: minPossibleWin,
            bonus: ticket.superBonus?.amount,
            bonusPercentage: 1
          }
        };
      },
      mapBetsForPreview({ stake, bets, config }) {
        const productId = config.ticketGroup;
        const title = productId.split(/(?=[A-Z])/).join(' ');

        const checkValue = (value) => {
          if (value.positions) {
            return Object.entries(value.positions)
              .map(([key, values]) => `${key.replace('.', '')}: ${values.join(', ')}`)
              .join('; ') + ';';
          }
          return value;
        };

        const mappedBets = bets.map((bet) => ({
          betId: bet.id,
          betStartTime: `${nabTrans.translate('games.event_round', {}, true)} ${bet.event}`,
          betEventName: bet.displayBetType ?? bet.displayValue,
          betName: bet.displayName,
          betOutcomeName: bet.pick ?? checkValue(bet.displayValue),
          betOutcomeOdd: bet.odds ?? null,
          payment: bet.payin
        }));

        return {
          title,
          productId,
          channel: '7ShopBetslip',
          ticket: {
            bets: mappedBets,
            payment: stake,
            stake: stake,
            maxWinning: null,
            bonus: null,
            bonusPercentage: 1
          }
        };
      }
    };
  }
})();
